* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter Tight', sans-serif;
  font-size: 16px;
  color: #000;

}
body::-webkit-scrollbar {
  display: none;
}